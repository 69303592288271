<template>
  <!-- <div class="min-h-full bg-gray-100 py-6 flex flex-col justify-center sm:py-12"> -->
  <div class="relative py-3 max-w-full mx-auto" id="retprog-widget">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"></loading>
    <div v-if="!send_booking" :class="container_classes
      ? container_classes
      : 'relative px-2 py-4 lg:px-10 lg:py-10 bg-white lg:mx-8 mx-0 shadow'
      ">
      <!-- <div class="max-w-full mx-auto"> -->
      <!-- Header -->
      <div class="flex items-center space-x-5">
        <div :class="icon_classes
      ? icon_classes
      : 'h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono'
      ">
          i
        </div>
        <div :class="title_container_classes
      ? title_container_classes
      : 'block pl-2 font-semibold text-xl self-start text-gray-700'
      ">
          <p :class="title_classes
      ? title_classes
      : 'lg:leading-relaxed sm:text-4xl text-xl'
      ">
            {{ $t("title") }} {{ car_id && car_name ? car_name : '' }}
          </p>
          <p class="
              text-sm text-gray-500
              sm:text-4xl
              lg:text-lg
              font-normal
              leading-relaxed
            ">
            {{ $t("all_fields") }}
          </p>
        </div>
      </div>
      <div class="divide-y divide-gray-200">
        <div :class="form_container
      ? form_container
      : 'py-4 text-base leading-6 space-y-4 text-gray-700 sm:text-lg lg:text-base'
      ">
          <div class="lg:flex items-center lg:space-x-4 justify-between">
            <div class="flex flex-col flex-grow mt-2 lg:mt-0">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("start_date") }}<span
                  style="color: red">*</span></label>
              <div class="">
                <date-picker v-model="start_date" :locale="locale" mode="date" :model-config="calendarConfig"
                  @input="start_date_error = null" :min-date="new Date()" is-required
                  :columns="$screens({ default: 1, lg: 2 })" :is-expanded="$screens({ default: true, lg: false })">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="
                        sm:h-24
                        lg:h-10
                        sm:text-4xl
                        lg:text-base
                        min-w-full
                        px-2
                        py-1
                        border
                        rounded
                        focus:outline-none focus:border-blue-300
                      " :value="inputValue" v-on="inputEvents" />
                  </template>
                </date-picker>
                <p v-if="start_date_error" class="text-red-600 sm:text-4xl lg:text-base">
                  {{ start_date_error }}
                </p>
              </div>
            </div>
            <div class="flex flex-col flex-grow mt-2 lg:mt-0">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("start_time") }}<span
                  style="color: red">*</span></label>
              <div class="">
                <date-picker v-model="start_time" mode="time" :locale="locale" is24hr is-required
                  :model-config="timeConfig" @input="start_date_error = null">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="
                        sm:h-24
                        lg:h-10
                        sm:text-4xl
                        lg:text-base
                        min-w-full
                        px-2
                        py-1
                        border
                        rounded
                        focus:outline-none focus:border-blue-300
                      " :value="inputValue" v-on="inputEvents" />
                  </template>
                </date-picker>
                <p v-if="start_date_error" class="text-red-600 sm:text-4xl lg:text-base">
                  {{ start_date_error }}
                </p>
              </div>
            </div>
            <div class="flex flex-col flex-grow mt-2 lg:mt-0">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("end_date") }}<span
                  style="color: red">*</span></label>
              <div class="relative">
                <date-picker v-model="end_date" :locale="locale" mode="date" :model-config="calendarConfig" is-required
                  @input="end_date_error = null" :min-date="min_date" :columns="$screens({ default: 1, lg: 2 })"
                  :is-expanded="$screens({ default: true, lg: false })">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="
                        sm:h-24
                        lg:h-10
                        sm:text-4xl
                        lg:text-base
                        min-w-full
                        px-2
                        py-1
                        border
                        rounded
                        focus:outline-none focus:border-blue-300
                      " :value="inputValue" v-on="inputEvents" />
                  </template>
                </date-picker>
                <p v-if="end_date_error" class="text-red-600 sm:text-4xl lg:text-base">
                  {{ end_date_error }}
                </p>
              </div>
            </div>
            <div class="flex flex-col flex-grow mt-2 lg:mt-0">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("end_time") }}<span
                  style="color: red">*</span></label>
              <div class="">
                <date-picker v-model="end_time" mode="time" :locale="locale" is24hr is-required
                  :model-config="timeConfig" @input="end_date_error = null">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="
                        sm:h-24
                        lg:h-10
                        sm:text-4xl
                        lg:text-base
                        min-w-full
                        px-2
                        py-1
                        border
                        rounded
                        focus:outline-none focus:border-blue-300
                      " :value="inputValue" v-on="inputEvents" />
                  </template>
                </date-picker>
                <p v-if="start_date_error" class="text-red-600 sm:text-4xl lg:text-base">
                  {{ start_date_error }}
                </p>
              </div>
            </div>
            <div class="flex flex-col flex-grow sm:mt-5">
              <button @click="searchCars()" :class="button_classes
      ? button_classes
      : 'text-4xl lg:text-lg h-14 lg:h-10 bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 mt-2 rounded-md focus:outline-none'
      ">
                {{ $t("search") }}
              </button>
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && !car_id" class="flex flex-col">
            <label v-if="show_all_cars" class="leading-loose sm:text-4xl lg:text-base">{{ returnRentalObjectName()
              }}<span style="color: red">*</span></label>
            <label v-else class="leading-loose sm:text-4xl lg:text-base">{{ returnRentalObjectName() }}<span
                style="color: red">*</span> ({{
      $t("free")
    }}: {{ cars.length }})</label>
            <select v-model="selected_car_id" value="id" @input="car_name_error = null" class="
                sm:h-24
                lg:h-10
                sm:text-4xl
                lg:text-base
                px-4
                py-2
                border
                focus:ring-gray-500 focus:border-gray-900
                w-full
                sm:text-sm
                border-gray-300
                rounded-md
                focus:outline-none
                text-gray-600
                placeholder-gray-500 placeholder-opacity-25
              " placeholder="Event title">
              <option disabled :value="null">
                {{ returnRentalObjectName() }}
              </option>
              <option v-for="car in cars" :key="car.index" :value="car.id">
                {{ car.car_name }} {{ car.transmission }} {{ hidden_car_year ? '' : car.year }}
              </option>
            </select>
            <p v-if="set_car" class="sm:text-4xl lg:text-base mt-2">
              {{ $t("price_for_day") }}: {{ set_car.selected_price }}
            </p>
            <p v-if="car_name_error" class="text-red-600 sm:text-4xl lg:text-base">
              {{ car_name_error }}
            </p>
          </div>
          <div v-if="cars &&
      cars.length > 0 &&
      places_arr &&
      places_arr.length > 0 &&
      !empty_cars
      " class="lg:flex items-center lg:space-x-4 justify-between">
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("start_place") }}<span
                  style="color: red">*</span></label>
              <select v-model="start_place" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="Офис" @change="start_place_error = false">
                <option disabled :value="{ place: null, price: 0 }">
                  {{ $t("start_place_select") }}
                </option>
                <option v-for="place in places_arr" :key="place.index" :value="place">
                  {{ place.place }}
                </option>
              </select>
              <p v-if="start_place_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ start_place_error }}
              </p>
            </div>
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("end_place") }}<span
                  style="color: red">*</span></label>
              <select v-model="end_place" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="Офис" @change="end_place_error = false">
                <option disabled :value="{ place: null, price: 0 }">
                  {{ $t("end_place_select") }}
                </option>
                <option v-for="place in places_arr" :key="place.index" :value="place">
                  {{ place.place }}
                </option>
              </select>
              <p v-if="end_place_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ end_place_error }}
              </p>
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars" class="lg:flex items-center lg:space-x-4 justify-between">
            <div v-if="show_email" class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">Email<span style="color: red"
                  v-if="email_required">*</span></label>
              <input v-model.trim="email" @input="email_error = null" type="email" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="john@mail.com" />
              <p v-if="email_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ email_error }}
              </p>
            </div>
            <div v-if="show_phone" class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("phone")
                }}<span style="color: red" v-if="phone_required">*</span></label>
              <input @maska="phone = $event.target.dataset.maskRawValue"
                v-maska="['+# (###) ##-##-###', '+# (###) ###-##-####']" @input="phone_error = null" type="tel" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " :placeholder="$i18n.locale == 'ru' ? '+79112223333' : '+351911222333'
      " />
              <p v-if="phone_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ phone_error }}
              </p>
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars" class="lg:flex items-center lg:space-x-4 justify-between">
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("lastname") }}<span
                  style="color: red">*</span></label>
              <input v-model.trim="lastname" @input="lastname_error = null" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " :placeholder="$t('lastname_placeholder')" />
              <p v-if="lastname_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ lastname_error }}
              </p>
            </div>
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("firstname") }}<span
                  style="color: red">*</span></label>
              <input v-model.trim="name" @input="name_error = null" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " :placeholder="$t('firstname_placeholder')" />
              <p v-if="name_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ name_error }}
              </p>
            </div>
            <div v-if="show_middlename" class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("middlename")
                }}<span style="color: red" v-if="middlename_required">*</span></label>
              <input v-model.trim="middlename" @input="middlename_error = null" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " :placeholder="$t('middlename')" />
              <p v-if="middlename_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ middlename_error }}
              </p>
            </div>
            <div v-if="show_birthday" class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("birthday")
                }}<span style="color: red" v-if="birthday_required">*</span></label>
              <!-- <date-picker
                v-model="birthday"
                mode="date"
                is-required
                @input="birthday_error = null"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="sm:h-24 lg:h-10 sm:text-4xl lg:text-base min-w-full px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </date-picker> -->
              <input v-model="birthday" v-maska="'##.##.####'" @input="birthday_error = null" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="22.05.1985" />
              <p v-if="birthday_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ birthday_error }}
              </p>
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && show_driver_license"
            class="lg:flex items-center lg:space-x-4 justify-between">
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("driver_license_series")
                }}<span style="color: red" v-if="driver_license_required">*</span></label>
              <input v-model.trim="driver_series" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="39AC" @input="driver_license_error = null" />
              <p v-if="driver_license_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ $t("driver_license_series_error") }}
              </p>
            </div>
            <div v-if="show_driver_license" class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("driver_license_number")
                }}<span style="color: red" v-if="driver_license_required">*</span></label>
              <input v-model.trim="driver_number" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="112233" @input="driver_license_error = null" />
            </div>
            <div v-if="show_driver_license" class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("driver_license_issue")
                }}<span style="color: red" v-if="driver_license_required">*</span></label>
              <input v-model.trim="driver_issued" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="ГИБДД г.Москвы 13.04.2020" @input="driver_license_error = null" />
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && show_passport"
            class="lg:flex items-center lg:space-x-4 justify-between">
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("passport_series")
                }}<span style="color: red" v-if="passport_required">*</span></label>
              <input v-model.trim="passport_series" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="2000" @input="passport_error = null" />
              <p v-if="passport_error" class="text-red-600 sm:text-4xl lg:text-base">
                {{ $t("passport_error") }}
              </p>
            </div>
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("passport_number")
                }}<span style="color: red" v-if="passport_required">*</span></label>
              <input v-model.trim="passport_number" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="112233" @input="passport_error = null" />
            </div>
            <div class="flex flex-col flex-grow mt-2">
              <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("passport_issue")
                }}<span style="color: red" v-if="passport_required">*</span></label>
              <input v-model.trim="passport_issued" type="text" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  placeholder-gray-500 placeholder-opacity-25
                " placeholder="ОВД г.Москвы 13.04.2020" @input="passport_error = null" />
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && show_address" class="flex flex-col">
            <label class="leading-loose sm:text-4xl lg:text-base">{{ $t("address")
              }}<span style="color: red" v-if="passport_required">*</span></label>
            <input v-model.trim="address" type="textarea" class="
                sm:h-24
                lg:h-10
                sm:text-4xl
                lg:text-base
                px-4
                py-2
                border
                focus:ring-gray-500 focus:border-gray-900
                w-auto
                sm:text-sm
                border-gray-300
                rounded-md
                focus:outline-none
                text-gray-600
                placeholder-gray-500 placeholder-opacity-25
              " :placeholder="$t('address_placeholder')" />
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && chairs_data" class="flex flex-col">
            <label class="sm:text-4xl text-base font-bold">
              <input v-model="chair_check" class="
                  sm:h-16 sm:w-16
                  lg:h-5 lg:w-5
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  mr-2
                " type="checkbox" />
              <span class="sm:text-4xl lg:text-base">
                {{ $t("baby_chair") }}
              </span>
            </label>
            <div v-if="cars && cars.length > 0 && !empty_cars && chair_check" class="flex flex-col">
              <label class="leading-loose sm:text-4xl lg:text-base">{{
      $t("baby_chair_quantity")
    }}</label>
              <input v-model.number="chair_quantity" type="number" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  lg:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                " />
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && boosters_data" class="flex flex-col">
            <label class="sm:text-4xl lg:text-base font-bold">
              <input v-model="booster_check" class="
                  sm:h-16 sm:w-16
                  lg:h-5 lg:w-5
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                  mr-2
                " type="checkbox" />
              <span class="sm:text-4xl lg:text-base">
                {{ $t("baby_chair_boster") }}
              </span>
            </label>
            <div v-if="cars && cars.length > 0 && !empty_cars && booster_check" class="flex flex-col">
              <label class="leading-loose sm:text-4xl lg:text-base">{{
      $t("baby_chair_boster_quantity")
    }}</label>
              <input v-model.number="booster_quantity" type="number" class="
                  sm:h-24
                  lg:h-10
                  sm:text-4xl
                  lg:text-base
                  px-4
                  py-2
                  border
                  focus:ring-gray-500 focus:border-gray-900
                  w-auto
                  sm:text-sm
                  border-gray-300
                  rounded-md
                  focus:outline-none
                  text-gray-600
                " />
            </div>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars && equipment_arr.length > 0
      " class="flex flex-col">
            <label class="typo__label">{{ $t("equipment") }}</label>
            <multiselect v-model="equipment_select" :options="equipment_arr" :custom-label="nameWithLang"
              :multiple="true" :close-on-select="false" :searchable="false" :placeholder="$t('select_equipment')"
              :selectLabel="$t('select')" :selectedLabel="$t('selected')" :deselectLabel="$t('remove')" label="item"
              track-by="item" :preselect-first="false">
            </multiselect>
          </div>
          <div v-if="cars && cars.length > 0 && !empty_cars" class="flex flex-col">
            <label class="leading-loose sm:text-4xl lg:text-base">{{
      $t("add_info")
    }}</label>
            <input v-model.trim="description" type="textarea" class="
                sm:h-24
                lg:h-10
                sm:text-4xl
                lg:text-base
                px-4
                py-2
                border
                focus:ring-gray-500 focus:border-gray-900
                w-auto
                sm:text-sm
                border-gray-300
                rounded-md
                focus:outline-none
                text-gray-600
                placeholder-gray-500 placeholder-opacity-25
              " :placeholder="$t('add_info_placeholder')" />
          </div>
        </div>
        <div v-if="cars && cars.length > 0 && set_car && !empty_cars">
          <p class="text-xl italic lg:text-lg">{{ $t("calculate") }}:</p>
          <div class="text-xl lg:text-lg">
            {{ $t("for_rent") }}: {{ set_car.selected_price }} * {{ days }} =
            {{ days * set_car.selected_price }}
          </div>
          <div v-if="set_car &&
      additional_hours() > 0 &&
      set_car.price_hour &&
      additional_hours() * set_car.price_hour > 0 &&
      !showDayWarn
      " class="text-xl lg:text-lg">
            {{ $t("for_add_hours") }}: {{ additional_hours() }} *
            {{ set_car.price_hour }} =
            {{ additional_hours() * set_car.price_hour }}
          </div>
          <div v-if="showDayWarn" class="text-xl lg:text-lg">
            {{ $t("for_add_hours_warn") }}
          </div>
          <div v-if="start_place.price > 0" class="text-xl lg:text-lg">
            {{ $t("for_delivery") }}: {{ start_place.price }}
          </div>
          <div v-if="chair_check && chairs_data && chairs_data.daily" class="text-xl lg:text-lg">
            {{ $t("for_chair") }}:
            {{ chairs_data.price * chair_quantity * days }}
          </div>
          <div v-if="chair_check && chairs_data && !chairs_data.daily" class="text-xl lg:text-lg">
            {{ $t("for_chair") }}: {{ chairs_data.price * chair_quantity }}
          </div>
          <div v-if="equipment_select.length > 0 && equipment_cost > 0" class="text-xl lg:text-lg">
            {{ $t("for_equipment") }}: {{ equipment_cost }}
          </div>
          <div v-if="booster_check && boosters_data && boosters_data.daily" class="text-xl lg:text-lg">
            {{ $t("for_boster") }}:
            {{ boosters_data.price * booster_quantity * days }}
          </div>
          <div v-if="chair_check && boosters_data && !boosters_data.daily" class="text-xl lg:text-lg">
            {{ $t("for_boster") }}: {{ boosters_data.price * booster_quantity }}
          </div>
          <div v-if="end_place.price > 0" class="text-xl lg:text-lg">
            {{ $t("for_return") }}: {{ end_place.price }}
          </div>
          <div class="text-xl lg:text-lg">
            {{ $t("deposit") }}: {{ set_car.deposit }}
          </div>
          <div class="text-xl lg:text-lg font-bold">
            {{ $t("for_total") }}: {{ total }} + {{ set_car.deposit }}
            {{ $t("for_deposit") }}
          </div>
        </div>
        <div v-if="empty_cars" class="pt-4 text-center text-xl lg:text-xl lg:space-x-4">
          <h2>{{ $t("no_free_cars") }}</h2>
          <p>
            {{ $t("no_free_cars_desc") }}
          </p>
        </div>
        <div v-if="cars && cars.length > 0 && !empty_cars" class="pt-4 text-center md:space-x-4">
          <div>
            <label class="leading-loose sm:text-xl text-xl mb-1">{{ $t("send_desc") }}
              <a style="color: blue; text-decoration: underline" :href="`https://rentprog.${$i18n.locale == 'ru' ? 'ru' : 'com'
      }/${$i18n.locale}/privacy`" target="_blank">{{ $t("policy") }}</a>
              <span v-if="agreement_data">{{ agreement_data.text }}
                <a style="color: blue; text-decoration: underline" :href="agreement_data.link" target="_blank">{{
      agreement_data.link_text }}</a>.</span>
            </label>
          </div>
          <button @click="recaptcha()" :class="button_classes
      ? button_classes
      : 'text-4xl lg:text-lg h-14 lg:h-10 bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 mt-2 rounded-md focus:outline-none'
      ">
            {{ $t("send") }}
          </button>
        </div>
        <div :class="hidden_copyright ? 'hidden' : 'pt-4 text-center md:space-x-4'" style="opacity: 0.2">
          <label class="leading-loose sm:text-xl lg:text-sm" style="color: grey">{{ $t("work_on") }}
            <a :href="`https://rentprog.${$i18n.locale == 'ru' ? 'ru' : 'com'}?ref=widget`"
              style="color: black; text-decoration: underline !important" target="_blank">CRM RentProg</a>
          </label>
        </div>
        <div v-if="send_booking_error" class="text-red-600 pt-4 text-center md:space-x-4">
          {{ send_booking_error }}
        </div>
      </div>
      <!-- </div> -->
    </div>
    <div v-else class="
        relative
        px-4
        py-10
        text-center
        bg-white
        mx-8
        md:mx-0
        shadow
        sm:p-10
      ">
      <h1>{{ $t("send_success") }}</h1>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import Multiselect from "vue-multiselect";
import { isMobile } from "mobile-device-detect";
export default {
  props: {
    token: String,
    locale: String,
    car_id: Number,
    car_name: String,
    show_all_cars: Boolean,
    places: String,
    chairs: String,
    boosters: String,
    equipment: String,
    agreement: String,
    passport_required: Boolean,
    driver_license_required: Boolean,
    email_required: Boolean,
    phone_required: Boolean,
    birthday_required: Boolean,
    middlename_required: Boolean,
    button_classes: String,
    container_classes: String,
    title_container_classes: String,
    title_classes: String,
    icon_classes: String,
    form_container: String,
    hidden_copyright: Boolean,
    hidden_car_year: Boolean,
    yandex_metrika_id: Number,
    show_email: {
      type: Boolean,
      default: true,
    },
    show_phone: {
      type: Boolean,
      default: true,
    },
    show_middlename: {
      type: Boolean,
      default: true,
    },
    show_birthday: {
      type: Boolean,
      default: true,
    },
    show_passport: {
      type: Boolean,
      default: true,
    },
    show_driver_license: {
      type: Boolean,
      default: true,
    },
    show_address: {
      type: Boolean,
      default: true,
    },
    rental_object: String
  },
  beforeCreate() {
    this.isLoading = true;
  },
  mounted() {
    this.checkMobile();
  },
  created() {
    // document.onreadystatechange = () => {
    //   //To load the app only after all libraries are loaded
    //   if (document.readyState == "complete") {
    //     this.isLoading = false;
    //   }
    // };
    console.log("created", this.car_id, this.car_name);
    this.isLoading = false;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.locale) {
      this.$i18n.locale = this.locale;
    }
    if (this.places) {
      this.places_arr = JSON.parse(this.places);
    }
    if (this.chairs) {
      this.chairs_data = JSON.parse(this.chairs);
    }
    if (this.boosters) {
      this.boosters_data = JSON.parse(this.boosters);
    }
    if (this.agreement) {
      this.agreement_data = JSON.parse(this.agreement);
    }
    if (this.equipment) {
      this.equipment_arr = JSON.parse(this.equipment);
    }
  },
  data() {
    return {
      cars: [],
      empty_cars: false,
      places_arr: [],
      start_place_price: 0,
      end_place_price: 0,
      set_car: null,
      selected_car_id: null,
      start_date: moment()
        .set("hour", 10)
        .set("minute", 0)
        .format("DD-MM-YYYY H:mm"),
      end_date: moment()
        .set("hour", 10)
        .set("minute", 0)
        .add(1, "day")
        .format("DD-MM-YYYY H:mm"),
      start_time: moment().set("hour", 10).set("minute", 0).format("H:mm"),
      end_time: moment().set("hour", 10).set("minute", 0).format("H:mm"),
      min_date: moment().format(),
      start_place: { place: null, price: 0 },
      end_place: { place: null, price: 0 },
      equipment_arr: [],
      equipment_select: [],
      email: null,
      phone: null,
      name: null,
      middlename: null,
      lastname: null,
      address: null,
      days: 0,
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm", // Uses 'iso' if missing,
        timeAdjust: "10:00",
      },
      timeConfig: {
        type: "string",
        mask: "H:mm",
        timeAdjust: "10:00",
      },
      passport_series: null,
      passport_number: null,
      passport_issued: null,
      driver_series: null,
      driver_number: null,
      driver_issued: null,
      birthday: null,
      description: null,
      send_booking: false,
      car_name_error: null,
      start_date_error: null,
      end_date_error: null,
      start_time_error: null,
      end_time_error: null,
      start_place_error: null,
      end_place_error: null,
      email_error: null,
      phone_error: null,
      name_error: null,
      birthday_error: null,
      lastname_error: null,
      middlename_error: null,
      equipment_select_error: null,
      send_booking_error: null,
      passport_error: null,
      driver_license_error: null,
      agreement_manual: false,
      agreement_152: false,
      chairs_data: null,
      boosters_data: null,
      chair_check: false,
      chair_quantity: 1,
      booster_check: false,
      booster_quantity: 1,
      agreement_data: null,
      showDayWarn: false,
      isMobile: false,
      isLoading: true,
      errors: [],
    };
  },
  watch: {
    start_date() {
      if (!this.start_time || this.start_time == "10:00") {
        this.start_time = moment(this.start_date, "DD-MM-YYYY H:mm")
          .startOf("day")
          .hour(10)
          .minute(0)
          .format("H:mm");
      }
      this.min_date = moment(this.start_date, "DD-MM-YYYY H:mm").format();
      this.set_car = null;
      this.cars = null;
      if (this.car_id) {
        this.selected_car_id = this.car_id;
      } else {
        this.selected_car_id = null;
      }
    },
    start_time() {
      let hour = moment(this.start_time, "H:mm").get("hour");
      let minute = moment(this.start_time, "H:mm").get("minute");

      this.start_date = moment(this.start_date, "DD-MM-YYYY H:mm")
        .set("hour", hour)
        .set("minute", minute)
        .format("DD-MM-YYYY H:mm");
      this.set_car = null;
      this.cars = null;
      if (this.car_id) {
        this.selected_car_id = this.car_id;
      } else {
        this.selected_car_id = null;
      }
    },
    end_date() {
      if (!this.end_time || this.end_time == "10:00") {
        this.end_time = moment(this.end_date, "DD-MM-YYYY H:mm")
          .startOf("day")
          .hour(10)
          .minute(0)
          .format("H:mm");
      }

      this.set_car = null;
      this.cars = null;
      if (this.car_id) {
        this.selected_car_id = this.car_id;
      } else {
        this.selected_car_id = null;
      }
    },
    end_time() {
      let hour = moment(this.end_time, "H:mm").get("hour");
      let minute = moment(this.end_time, "H:mm").get("minute");

      this.end_date = moment(this.end_date, "DD-MM-YYYY H:mm")
        .set("hour", hour)
        .set("minute", minute)
        .format("DD-MM-YYYY H:mm");
      this.set_car = null;
      this.cars = null;
      if (this.car_id) {
        this.selected_car_id = this.car_id;
      } else {
        this.selected_car_id = null;
      }
    },
    selected_car_id() {
      if (this.selected_car_id) {
        this.days = Math.round(
          moment
            .duration(
              moment(this.end_date, "DD-MM-YYYY H:mm").diff(
                moment(this.start_date, "DD-MM-YYYY H:mm")
              )
            )
            .asMinutes() / 60 / 24
        );
        console.log("days", this.days);
        // :id/:start_date/:end_date/:days
        this.axios
          .post(
            `/api/v1/widget_car`,
            {
              id: this.selected_car_id,
              start_date: this.start_date,
              end_date: this.end_date,
              // days: this.days, // считаю на бэке
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            this.set_car = response.data;
            if (!this.cars || this.cars.length == 0) {
              this.cars = [];
              this.cars.push(this.set_car);
            }
            // console.log(response.data)
            // Считаем доп часы по условиям
            this.hours_cost_calc();
          })
          .catch((error) => {
            console.log(error);
            this.$swal({
              showConfirmButton: true,
              icon: "error",
              title: this.$t("date_error_22"),
              text: this.$t("date_error_5"),
            });
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
  computed: {
    // start_date_formated() {
    //   if (this.start_date) {
    //     return moment(this.start_date).format("DD-MM-YYYY H:mm");
    //   } else {
    //     return null;
    //   }
    // },
    // end_date_formated() {
    //   if (this.end_date) {
    //     return moment(this.end_date).format("DD-MM-YYYY H:mm");
    //   } else {
    //     return null;
    //   }
    // },
    equipment_cost() {
      let cost = 0;
      if (this.equipment_select && this.equipment_select.length > 0) {
        this.equipment_select.forEach((item) => {
          if (item.daily) {
            cost += item.price * this.days;
          } else {
            cost += item.price;
          }
        });
      }
      return cost;
    },
    equipment_text() {
      let text = "";
      if (
        this.equipment_arr &&
        this.equipment_arr.length > 0 &&
        this.equipment_select &&
        this.equipment_select.length > 0
      ) {
        text = this.$t("equipment_2");
        this.equipment_select.forEach((item) => {
          text += `${item.item} - ${item.price}, `;
        });
      }
      return text;
    },
    total() {
      if (this.set_car) {
        let rental_cost = 0;
        rental_cost = this.days * this.set_car.selected_price;

        return (
          rental_cost +
          this.hours * this.set_car.price_hour +
          this.start_place.price +
          this.end_place.price +
          this.equipment_cost +
          this.equipment_total()
        );
      } else {
        return 0;
      }
    },
  },
  methods: {
    searchCars() {
      let self = this;
      if (
        moment(this.start_date, "DD-MM-YYYY H:mm").isSameOrAfter(
          moment(this.end_date, "DD-MM-YYYY H:mm")
        )
      ) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: self.$t("date_error_1"),
          text: self.$t("date_error_1"),
        });
      } else {
        if (
          this.start_date &&
          this.end_date &&
          this.start_time &&
          this.end_time
        ) {
          if (this.token) {
            this.isLoading = true;
            if (this.show_all_cars) {
              this.axios
                .get(`/api/v1/all_cars`, {
                  headers: {
                    Authorization: "Bearer " + this.token,
                  },
                })
                .then((response) => {
                  this.selected_car_id = null;
                  this.set_car = null;
                  if (this.locale == "en") {
                    this.cars = [];
                    let cars_data = response.data;
                    cars_data.forEach((car) => {
                      if (car.transmission == "Автомат") {
                        car.transmission = "Automatic";
                      }
                      if (car.transmission == "Вариатор") {
                        car.transmission = "Automatic";
                      }
                      if (car.transmission == "Робот") {
                        car.transmission = "Automatic";
                      }
                      if (car.transmission == "Механика") {
                        car.transmission = "Manual";
                      }
                      console.log(car);
                      this.cars.push(car);
                    });
                  } else {
                    this.cars = response.data;
                  }
                  if (this.cars == 0) {
                    this.empty_cars = true;
                  } else {
                    this.empty_cars = false;
                  }
                  // click yandex metrika goals
                  if (window["ym"] && this.yandex_metrika_id) {
                    console.log("search_cars_metrika", this.yandex_metrika_id);
                    window["ym"](this.yandex_metrika_id, "reachGoal", "search_cars", {
                      start_date: this.start_date,
                      end_date: this.end_date,
                    }, () => {
                      console.log("search_cars_metrika success");
                    }, (err) => {
                      console.log("search_cars_metrika error", err);
                    });
                  } else {
                    console.log("search_cars_metrika not found");
                  }
                  this.isLoading = false;
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.send_booking_error = this.$t("date_error_3");
                  console.log(error);
                });
            } else if (!this.show_all_cars && !this.car_id) {
              this.axios
                .get(
                  `/api/v1/widget_cars?start_date=${this.start_date}&end_date=${this.end_date}`,
                  {
                    headers: {
                      Authorization: "Bearer " + this.token,
                    },
                  }
                )
                .then((response) => {
                  this.selected_car_id = null;
                  this.set_car = null;

                  if (this.locale == "en") {
                    this.cars = [];
                    let cars_data = response.data;
                    cars_data.forEach((car) => {
                      if (car.transmission == "Автомат") {
                        car.transmission = "Automatic";
                      }
                      if (car.transmission == "Вариатор") {
                        car.transmission = "Automatic";
                      }
                      if (car.transmission == "Робот") {
                        car.transmission = "Automatic";
                      }
                      if (car.transmission == "Механика") {
                        car.transmission = "Manual";
                      }
                      console.log(car);
                      this.cars.push(car);
                    });
                  } else {
                    this.cars = response.data;
                  }
                  if (this.cars == 0) {
                    this.empty_cars = true;
                  } else {
                    this.empty_cars = false;
                  }
                  // click yandex metrika goals
                  if (window["ym"] && this.yandex_metrika_id) {
                    console.log("search_cars_metrika", this.yandex_metrika_id);
                    window["ym"](this.yandex_metrika_id, "reachGoal", "search_cars", {
                      start_date: this.start_date,
                      end_date: this.end_date,
                    }, () => {
                      console.log("search_cars_metrika success");
                    }, (err) => {
                      console.log("search_cars_metrika error", err);
                    });
                  } else {
                    console.log("search_cars_metrika not found");
                  }
                  this.isLoading = false;
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.send_booking_error = this.$t("date_error_3");
                  console.log(error);
                });
            } else if (!this.show_all_cars && this.car_id) {
              this.selected_car_id = this.car_id;
            }
          } else {
            console.log("Отсутствует токен");
            this.$swal({
              showConfirmButton: true,
              icon: "error",
              title: self.$t("date_error_4"),
              text: self.$t("date_error_5"),
            });
          }
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: self.$t("date_error_6"),
          });
        }
      }
    },
    sendBooking() {
      console.log("send booking");
      let self = this;
      this.car_name_error = null;
      this.start_date_error = null;
      this.end_date_error = null;
      this.start_place_error = null;
      this.end_place_error = null;
      this.email_error = null;
      this.phone_error = null;
      this.name_error = null;
      this.birthday_error = null;
      this.lastname_error = null;
      this.middlename_error = null;
      this.send_booking_error = null;
      this.driver_license_error = null;
      this.passport_error = null;

      if (
        !this.start_date ||
        !this.end_date ||
        !this.set_car ||
        (this.places && (this.start_place.place == null ||
          this.end_place.place == null)) ||
        !this.name ||
        !this.lastname ||
        !this.token ||
        (this.passport_required &&
          !(this.passport_series || this.passport_number)) ||
        (this.driver_license_required &&
          (!this.driver_series || !this.driver_number)) ||
        (this.email_required && !this.email) ||
        (this.phone_required && !this.phone) ||
        (this.birthday_required && !this.birthday) ||
        (this.middlename_required && !this.middlename)
      ) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: self.$t("date_error_7"),
        });
        if (!this.start_date) {
          this.start_date_error = self.$t("date_error_8");
        }
        if (!this.end_date) {
          this.end_date_error = self.$t("date_error_9");
        }
        if (!this.set_car) {
          this.car_name_error = self.$t("date_error_10");
        }
        if (this.places && this.start_place.place == null) {
          this.start_place_error = self.$t("date_error_11");
        }
        if (this.places && this.end_place.place == null) {
          this.end_place_error = self.$t("date_error_12");
        }
        if (this.phone_required && !this.phone) {
          this.phone_error = self.$t("date_error_13");
        }
        if (this.email_required && !this.email) {
          this.email_error = self.$t("date_error_14");
        }
        if (!this.name) {
          this.name_error = self.$t("date_error_15");
        }
        if (!this.lastname) {
          this.lastname_error = self.$t("date_error_16");
        }
        if (this.birthday_required && !this.birthday) {
          this.birthday_error = self.$t("date_error_17");
        }
        if (this.middlename_required && !this.middlename) {
          this.middlename_error = self.$t("date_error_21");
        }
        if (!this.token) {
          this.send_booking_error = self.$t("date_error_18");
        }
        if (
          this.driver_license_required &&
          (!this.driver_series || !this.driver_number)
        ) {
          this.driver_license_error = self.$t("date_error_19");
        }
        if (
          this.passport_required &&
          (!this.passport_series || !this.passport_number)
        ) {
          this.passport_error = self.$t("date_error_20");
        }
      } else {
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/booking_from_widget",
            {
              car_id: this.set_car.id,
              car_name: this.set_car.car_name,
              car_code: this.set_car.code,
              deposit: this.set_car.deposit,
              start_date: this.start_date,
              end_date: this.end_date,
              start_place: this.start_place.place,
              end_place: this.end_place.place,
              email: this.email,
              phone: this.phone,
              name: this.name,
              middlename: this.middlename,
              lastname: this.lastname,
              passport_series: this.passport_series,
              passport_number: this.passport_number,
              passport_issued: this.passport_issued,
              driver_series: this.driver_series,
              driver_number: this.driver_number,
              driver_issued: this.driver_issued,
              birthday: this.birthday
                ? moment(this.birthday, "DD.MM.YYYY").format("YYYY-MM-DD")
                : null,
              address: this.address,
              description:
                this.equipment_text && this.equipment_text != ""
                  ? `${this.description ? this.description : ""} ${this.equipment_text
                  }`
                  : `${this.description ? this.description : ""}`,
              days: this.days,
              additional_hours: this.hours,
              price: this.set_car.selected_price,
              price_no_sale: this.set_car.selected_price,
              rental_cost: this.days * this.set_car.selected_price,
              price_hour: this.set_car.price_hour,
              hours_cost:
                this.hours > 0 ? this.hours * this.set_car.price_hour : 0,
              delivery: this.start_place.price,
              delivery_end: this.end_place.price,
              chair: this.chair_check,
              booster: this.booster_check,
              chairs_quantity: this.chair_check ? this.chair_quantity : 0,
              boosters_quantity: this.booster_check ? this.booster_quantity : 0,
              equipment: this.equipment_total() + this.equipment_cost,
              total: this.total,
              show_all_cars: this.show_all_cars,
            },
            {
              headers: {
                Authorization: "Bearer " + self.token,
              },
            }
          )
          .then(() => {
            // click yandex metrika goals
            if (window["ym"] && this.yandex_metrika_id) {
              console.log("send_booking_metrika", this.yandex_metrika_id);
              window["ym"](this.yandex_metrika_id, "reachGoal", "send_booking", {
                start_date: this.start_date,
                end_date: this.end_date,
                car_id: this.set_car.id,
              }, () => {
                console.log("send_booking_metrika success");
              }, (err) => {
                console.log("send_booking_metrika error", err);
              });
            } else {
              console.log("send_booking_metrika not found");
            }
            this.send_booking = true;
            this.set_car = null;
            this.start_date = null;
            this.end_date = null;
            this.start_place = { place: null, price: 0 };
            this.end_place = { place: null, price: 0 };
            this.email = null;
            this.phone = null;
            this.name = null;
            this.days = 0;
            this.middlename = null;
            this.lastname = null;
            this.car_name_error = null;
            this.start_date_error = null;
            this.end_date_error = null;
            this.start_place_error = null;
            this.end_place_error = null;
            this.email_error = null;
            this.phone_error = null;
            this.name_error = null;
            this.middlename_error = null;
            this.lastname_error = null;
            this.send_booking_error = null;
            this.driver_license_error = null;
            this.passport_error = null;
            this.chair_check = false;
            this.booster_check = false;
            this.showDayWarn = false;
            this.isLoading = false;

            this.$swal({
              showConfirmButton: true,
              icon: "success",
              title: self.$t("send_success"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 406) {
              this.send_booking_error = self.$t("car_not_free");
              console.log(error);
            } else {
              this.send_booking_error = self.$t("date_error_18");
              console.log(error);
              alert(self.$t("date_error_18"));
            }
          });
      }
    },
    equipment_total() {
      let chair_cost = 0;
      let booster_cost = 0;

      if (this.chair_check) {
        if (this.chairs_data.daily) {
          chair_cost = this.chairs_data.price * this.days * this.chair_quantity;
        } else {
          chair_cost = this.chairs_data.price * this.chair_quantity;
        }
      }

      if (this.booster_check) {
        if (this.boosters_data.daily) {
          booster_cost =
            this.boosters_data.price * this.days * this.booster_quantity;
        } else {
          booster_cost = this.boosters_data.price * this.booster_quantity;
        }
      }

      return chair_cost + booster_cost;
    },
    hours_cost_calc() {
      if (
        (this.set_car.hours_limit > 0 &&
          this.additional_hours() > this.set_car.hours_limit) ||
        (this.set_car.hours_limit == 0 &&
          this.additional_hours() * this.days * this.set_car.selected_price) >
        this.additional_hours() * this.set_car.price_hour
      ) {
        if (this.additional_hours() < 12) {
          this.days += 1;
        }
        console.log("days += 1", this.days, this.additional_hours())
        this.hours = 0;
        this.showDayWarn = true;
      } else {
        this.days = Math.round(
          moment
            .duration(
              moment(this.end_date, "DD-MM-YYYY H:mm").diff(
                moment(this.start_date, "DD-MM-YYYY H:mm")
              )
            )
            .asMinutes() / 60 / 24
        );
        this.hours = this.additional_hours();

        this.showDayWarn = false;
        console.log("days", this.days)
      }
      console.log(this.hours);
    },
    returnRentalObjectName() {
      if (this.rental_object) {
        return this.rental_object;
      } else {
        return this.$t("select_car");
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
      console.log("isMobile", this.isMobile);
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()
        .then(() => this.sendBooking())
        .catch((error) => console.log(error));

      // // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha('login')

      // // Do stuff with the received token.
      // if (token) {
      //   this.sendBooking()
      // }
    },
    additional_hours() {
      if (this.set_car) {
        let additionalHours = moment
          .duration(
            moment(this.end_date, "DD-MM-YYYY H:mm").diff(
              moment(this.start_date, "DD-MM-YYYY H:mm")
            )
          )
          .asHours();
        return Math.trunc(additionalHours % 24);
      } else {
        return 0;
      }
    },
    hideCalendar(hide) {
      hide();
    },
    nameWithLang({ item }) {
      return item;
    },
  },
  components: { Multiselect },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css" src="./../node_modules/tailwindcss/tailwind.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
p {
  color: black;
}

.vc-select select {
  background-image: none;
}

.vc-time-picker {
  justify-content: center !important;
  margin: 0px auto !important;
}

.signupselect {
  background: url(https://i.ibb.co/fCsFTVL/icn-dropdown-chevron.png) no-repeat;
  width: 15px;
  height: 8px;
  background-size: contain;
}

.vue-tel-input {
  font-size: 24px !important;
}

@media (max-width: 1000px) {
  .vc-container.vc-is-expanded {
    min-width: 700px !important;
    min-height: 400px !important;
  }

  .vc-day-content {
    font-size: 27px !important;
    margin-top: 15px !important;
  }

  .vc-time-picker {
    justify-content: center !important;
  }

  .vc-title {
    font-size: 30px !important;
  }

  .vc-weekday {
    font-size: 20px !important;
  }

  .vc-date {
    font-size: 25px !important;
  }

  .vc-time.vc-select {
    font-size: 30px !important;
  }
}
</style>
